import React, {useEffect, useState} from "react";
import {RootState} from "../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {GenerationEntity, getEntities} from "./GenerationPRComparisonChartReducer.reducer";
import {format} from "date-fns";
import {truncateNumber} from "../../../Utils/NumberUtil";
import {
    AChartX,
    Period,
    XAxis,
    YAxis,
    Skeleton,
    PeriodSelectorGroupProps
} from "@atiautomacao/ati-ui-library"
import {checkDiffDays} from "../../../Utils/DataUitils";
import DataNotFound from "../../../Shared/Components/DataNotFoundMessage";
import useInterval from "../../../Shared/Hooks/useInterval";
import {useTheme} from "@mui/material";

interface SeriesOptions {
    name: string;
    type: string;
    unity: string;
    data: any[];
    barWidth?: any;
}

interface GenerationPRChartComparisonProps {
    period?: Period;
    periodIntervalGroup? : PeriodSelectorGroupProps;
}

export function GenerationPRChartComparison(props: GenerationPRChartComparisonProps) {
    const theme = useTheme();
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const generation: Array<GenerationEntity> = useAppSelector((state: RootState) => state.generationPRComparisonChart.entities);
    const { loading } = useAppSelector((state: RootState) => state.generationPRComparisonChart);

    const dispatch = useAppDispatch();
    // Setting range start period
    const initialPeriod: Period = {
        groupBy: "day",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };

    const [period, setPeriod] = useState<Period>(props.period ? props.period : initialPeriod);
    const [data, setData] = useState<Array<SeriesOptions>>(new Array<SeriesOptions>);
    const [dateTime, setDateTime] = useState<Array<string>>([]);
    const [yAxis, setYAxis] = useState<YAxis>();
    const [xAxis, setXAxis] = useState<XAxis>();

    useEffect(() => {
        if(props.period){
            setPeriod(props.period);
        }
    }, [props.period]);

    function ordination(newData: Array<GenerationEntity>){
        if(newData.length > 0){
            newData.sort(function compare (a, b){
                if(a.value > b.value){ return 1; }
                if(a.value < b.value){ return -1; }
                else{ return  0; }
            })
        }
        return newData;
    }
    const callback = (args:any) => {
        let date= dateTime

        let result : any =   args[0].name + '<br />  ' + (date[args[0].dataIndex]? date[args[0].dataIndex] + '<br /> ' : '')
        for(const element of args) {
            result += '<br/>' + element.marker + element.seriesName + ': ' + element.data + ' '+ '%' + '<br />'
        }
        return result;
    }

    function formatCaptionData(fromDateTime: Date, toDateTime: Date): string{

        let captionData;

        if (period.groupBy.valueOf() === 'year') {
            captionData = format(fromDateTime, 'yyyy') + ' à ' + format(toDateTime, 'yyyy')
        } else if (period.groupBy.valueOf() === "week" || period.groupBy.valueOf() === "month") {
            captionData = format(fromDateTime, 'dd/MM') + ' à ' + format(toDateTime, 'dd/MM')
        } else {
            captionData = format(fromDateTime, 'HH:mm' ) + ' à ' + format(toDateTime, 'HH:mm')
        }

        return captionData
    }

    function dateByPeriod(): any{

        let fromDateTime = new Date();
        let toDateTime = new Date();
        let captionData :  Array<string>  = new Array<string>();

        if(period.fromDateTime) {
            fromDateTime = period.fromDateTime
        }
        if(period.toDateTime) {
            toDateTime = period.toDateTime
        }

        generation.forEach(()=>{
            captionData.push(formatCaptionData(fromDateTime, toDateTime))
        })

        setDateTime(captionData);
    }
    function findGeneratedYieldComparisonDataByPeriod(){
        let aux = [...generation]
        let receiveData =  ordination(aux);
        setXAxis({
            min: 0,
            max: 100,
            interval: 25,
        });
        setYAxis({
            type: 'category',
            data: Array.isArray(receiveData) ? receiveData.map(item => item.powerStationName ) : [],
            axisLabel: {
                align: 'right',
                show: true
            },
            axisTick: { show: false },
            axisLine: { show: false },
            });
        setData( [
            {
                name: 'Atual',
                data: Array.isArray(receiveData) ? receiveData.map(item => item.value ? truncateNumber(item.value, 2) : "-") : [],
                type: 'bar',
                unity: '%'
            },
            {
                name: 'Esperado',
                data: Array.isArray(receiveData) ? receiveData.map(item => item.expectedValue ? truncateNumber(item.expectedValue, 2) : "-") : [],
                type: 'bar',
                unity: '%'
            }
        ]);
    }

    const fetchEntities = () => {
        if (checkDiffDays(period.toDateTime, period.fromDateTime) > 0) {
            dispatch(getEntities(period));
        }
    };
    useEffect(() => {
        fetchEntities();
    },[period]);

    useInterval(() => {
        fetchEntities();
    }, 60000) // 1 minute

    useEffect(() => {
        if(Array.isArray(generation) && generation.length > 0) {
            dateByPeriod();
            findGeneratedYieldComparisonDataByPeriod();
        }else {
            setData([])
        }
    },[generation]);

    const generationLength = generation === null || generation.length <= 0 ? 1 : generation.length;

    // @ts-ignore
    const option: AChartXProps['option'] = {
        color: ['#97d9e3', 'rgb(234, 162, 40)'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            formatter: callback
        },
        legend: {
            type: 'scroll',
            orient: 'vertical',
            right: 25,
            top: 40,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            textStyle: {
                opacity: 1
            },
            itemStyle: {
                opacity: 1
            },
            data: ["Atual", "Esperado"]
        },
        grid: {
            containLabel: true,
            left: '3%',
            right: '4%',
            bottom: '3%'
        },

        dataZoom :[
            {
                type: 'slider',
                yAxisIndex: generationLength <= 5 ? 1 : 0 ,
                filterMode: 'filter',
                zoomLock: true,
                width: 10,
                right: 10,
                top: '10%',
                bottom: '10%',
                start: (100-(100*5)/generationLength) ,
                brushSelect: false,
                handleSize: '60%',
                showDetail: false,
                opacity: 1,
                brushStyle: false,
            },
            {
                type: 'inside',
                id: 'insideY',
                yAxisIndex: 0,
                zoomOnMouseWheel: false,
                moveOnMouseMove: true,
                moveOnMouseWheel: true,
                backgroundColor: 'transparent'
            }
        ] ,
        xAxis: xAxis,
        yAxis: yAxis,
        series: data,
    };

    return(
        <>
            {
                loading ?
                    <Skeleton animation="wave" height={289} variant="rounded" width={'100%'} />
                    :
                    data.length > 0 && yAxis && xAxis ?
                        <AChartX
                            key={`card-overview-pr-comparison-${openSubMenu}`}
                            option={option}
                            height={289}
                            width={"100%"}
                            loading={false}
                            theme={theme.palette.mode}
                        />
                        : <DataNotFound boxStyle={{height: 289, width: '100%'}}/>

            }
        </>
    );
}