import React, {FunctionComponent, useEffect, useState} from "react";
import {RootState} from "../../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../../Config/Hooks";
import {GenerationEntity, getEntities} from "./GenerationEnergySkidChartReducer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import {truncateNumber} from "../../../../Utils/NumberUtil";
import {format} from "date-fns";
import {ptBR} from "date-fns/locale";
import {isArray} from "lodash";
import {
    ACard,
    AChartX,
    APopoverIcon,
    Period,
    PeriodSelector,
    Skeleton,
    XAxis,
    YAxis
} from "@atiautomacao/ati-ui-library"
import {useSnackbar} from "notistack";
import {checkDiffDays} from "../../../../Utils/DataUitils";
import DataNotFound from "../../../../Shared/Components/DataNotFoundMessage";
import useInterval from "../../../../Shared/Hooks/useInterval";
import {useTheme} from "@mui/material";

interface SeriesOption {
    name: string;
    type: string;
    unity: string;
    data: any[];
    smooth?: boolean
}
const GenerationEnergySkidChart: FunctionComponent<{powerPlant: any, skid: any}> = ({powerPlant, skid}) =>  {

    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const generation: Array<GenerationEntity> = useAppSelector((state: RootState) => state.generationEnergySkid.entities);
    const { loading, errorMessage } = useAppSelector((state: RootState) => state.generationEnergySkid);
    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useAppDispatch();
    const theme = useTheme();
    // Setting range start period
    const initialPeriod: Period = {
        groupBy: "week",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };
    const [period, setPeriod] = useState<Period>(initialPeriod);
    const [series, setSeries] = useState<Array<SeriesOption>>();
    const [xAxis, setXAxis] = useState<XAxis>();
    const [yAxis, setYAxis] = useState<Array<YAxis>>();

    const max = () => {
        const maxGeneratedEnergy= Array.isArray(generation) ? Math.max(...generation.map(item => item.generatedEnergy)) : 0
        const maxInjectedEnergy = Array.isArray(generation) ? Math.max(...generation.map(item => item.injectedEnergy)) : 0
        return  maxGeneratedEnergy > maxInjectedEnergy ?  truncateNumber(maxGeneratedEnergy + (maxGeneratedEnergy * 0.12)) : truncateNumber(maxInjectedEnergy + (maxInjectedEnergy * 0.12));
    }
    function findGeneratedEnergyDataByPeriod() {

        let xAxisName = "Dia";
        let datePeriod: string[] = [];
        let nameGap = 58

        if (period.groupBy.valueOf() === "week" || period.groupBy.valueOf() === "month" || period.groupBy.valueOf() === 'none') {
            datePeriod = generation ? generation
                .map(item => format(new Date(item.dateTime+"T00:00:00"), "dd/MM")) : [];
            nameGap = 35
        } else if (period.groupBy.valueOf() === "year") {
            xAxisName = 'Mês';
            datePeriod = Array.isArray(generation) ? generation
                .map(item => format(new Date(item.dateTime+"T00:00:00"), "MMM", {locale: ptBR})) : []
            nameGap = 48
        } else if (period.groupBy.valueOf() === "general") {
            datePeriod = Array.isArray(generation) ? generation.map(item => format(new Date(item.dateTime+"T00:00:00"), "yyyy")) : [];
            xAxisName = 'Ano';
        }

        setXAxis({
            name: xAxisName,
            data: datePeriod,
            nameLocation: "middle",
            nameGap: 21
        });
        const maxAxis = max();
        setYAxis([{
            name: 'MWh',
            max: maxAxis,
            min: 0,
            interval: truncateNumber(maxAxis / 4 ),
            nameGap: nameGap,
            nameLocation: "middle"
        }
        ])

        setSeries([
                {
                    name: 'Energia Gerada',
                    data: Array.isArray(generation) ? generation.map(item => item.generatedEnergy ?  truncateNumber(item.generatedEnergy,2) : "-") : [],
                    type: 'bar',
                    unity: 'MWh',
                    smooth: true
                },
                {
                    name: 'Energia Injetada',
                    data: Array.isArray(generation) ? generation.map(item => item.injectedEnergy ? truncateNumber(item.injectedEnergy,2) : "-") : [],
                    type: 'bar',
                    unity: 'MWh',
                    smooth: true
                }
            ]
        );

    }
    useEffect(() => {
        if(checkDiffDays(period.toDateTime, period.fromDateTime)){
            dispatch(getEntities({period, powerPlant, skid}));
        }
    }, [period, powerPlant, skid, dispatch]);

    useInterval(() => {
        if(checkDiffDays(period.toDateTime, period.fromDateTime)){
            dispatch(getEntities({period, powerPlant, skid}));
        }
    }, 60000) // 5 minutes

    useEffect(() => {
        if(isArray(generation) && generation.length > 0){
            findGeneratedEnergyDataByPeriod();
        }else {
            setSeries([]);
            setXAxis(undefined);
        }
    }, [generation]);


    // @ts-ignore
    const option: AChartXProps['option'] = {
        color: ['#5470C6', '#E56C6C'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        xAxis: xAxis,
        yAxis: yAxis,
        legend: {
            show: true,
            orient: 'vertical',
            right: 45,
            top: 10,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            textStyle: {
                opacity: 1
            },
            itemStyle: {
                opacity: 1
            },
            data: ['Energia Gerada', 'Energia Injetada']
        },
        grid: {
            containLabel: true,
            top: 10,
            bottom: 10
        },
        label: {
            precision: 1,
            position: 'right',
            valueAnimation: true,
            fontFamily: 'monospace'
        },
        animationDuration: 2000,
        series: series
    };

    useEffect(() => {
        if(errorMessage) {
            enqueueSnackbar(errorMessage, {variant: 'error'})
        }
    }, [errorMessage]);

    return (
        <>
            <ACard
                key={`chart-skid-energy-${openSubMenu}`}
                headerControlsPosition={"header"}
                styleProps={{
                    cardStyle: { height: 445 },
                    contentStyle: { padding: 0 }
                }}
                title="Energia Gerada vs Injetada"
                headerControls={
                    <>
                        <PeriodSelector
                            styleProps={{base: {flexDirection: "row", display: "flex", alignItems: "center"}}}
                            mode="hybrid"
                            hideDatetime={false}
                            hideGroup={false}
                            disableHourSelector={true}
                            inputFormat={'dd/MM/yyyy'}
                            periodInterval={{
                                week: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 6},
                                month: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 30},
                                year: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 12},
                                general: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 7}
                            }}
                            disabled={loading}
                            period={period}
                            onChange={setPeriod}
                            groupByOptions={["week", "month", "year", "general"]}
                        />
                    </>
                }
                // headerActions={
                //     <>
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
                //             text="Settings"
                //             disable={true}
                //             link={"/"}
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
                //             text="Expand"
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
                //             text="Print"
                //         />
                //     </>
                // }
                footerActions={
                    <APopoverIcon icon={<FontAwesomeIcon icon={faDownload} fontSize={20}/>}>
                        <IconButton disabled={true}>
                            <FontAwesomeIcon icon={faFileCsv} fontSize={20} />
                        </IconButton>
                    </APopoverIcon>
                }
            >
                {
                    loading ?
                        <Skeleton animation="wave" height={289} variant="rounded" width={"100%"} />
                        :
                        series && xAxis && yAxis ?
                            <AChartX
                                option={option}
                                height={289}
                                width={"100%"}
                                loading={false}
                                theme={theme.palette.mode}
                            />
                            :
                            <DataNotFound boxStyle={{height: 289, width: '100%'}}/>
                }
            </ACard>
        </>
    );
}
export default GenerationEnergySkidChart;
