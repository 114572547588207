import {ACard, AChartX, Skeleton} from "@atiautomacao/ati-ui-library";
import React, {FunctionComponent} from "react";
import {SynopticData} from "./SynopticChartReducer";
import {truncateNumber} from "../../../Utils/NumberUtil";
import {mapPowerStationStatusToColor} from "../../../Shared/Helpers/mapPowerStationStatusToColor";
import {useTheme} from "@mui/material";
import {useAppSelector} from "../../../Config/Hooks";

const COLORS= {maxLimit: "#ff0000", midLimit: "#ecc900", minLimit: "#91CC75"}

const SynopticChart: FunctionComponent<{synopticData:SynopticData, isLoading: boolean | undefined}> = ({synopticData, isLoading}) => {
    const theme = useTheme();
    const expectedInjectedEnergy = synopticData.expectedInjectedEnergy ? truncateNumber(synopticData.expectedInjectedEnergy, 2) : 0
    const expectedIrradiance = synopticData.expectedIrradiance ? truncateNumber(synopticData.expectedIrradiance , 2) : 0
    const generatedEnergyValue = synopticData.generatedEnergy ? truncateNumber(synopticData.generatedEnergy, 2) : 0
    const irradianceValue = synopticData.irradiance ? truncateNumber(synopticData.irradiance,2) : 0
    const generationRateValue = synopticData.generationRate ? truncateNumber(synopticData.generationRate, 2) : 0
    const activePowerValue = synopticData.activePower ? truncateNumber(synopticData.activePower, 2) : 0
    const radius = 88
    const customColor = (value: any)=> {
        if(value){
            if (value > 80) {
                return COLORS.minLimit;
            }
            else if (value >= 60 && value <= 80 ) {
                return COLORS.midLimit;
            }
            else {
                return COLORS.maxLimit;
            }
        }else{
            return COLORS.midLimit;
        }
    }

    const setMax = (value:number, maxValue:number) => {
        if(maxValue === 0){
            if(value === 0){
                return 100
            }
            return 30000
        }
        return maxValue
    }

    const valueFormatter = (value:any,unity:any,expected:any) => {
        return  `${value}${unity} de 
        ${expected}${unity} esperada`;
    }

    const generatedEnergyData = [{
        value: generatedEnergyValue,
        name: 'Energia Gerada',
        title: {
            show: false
        },
        detail: {
            show: false
        },
        itemStyle: {
            color: '#5470C6'
        }
        }];

    const irradianceData = [{
        value: irradianceValue,
        name: 'Irradiação',
        title: {
            show: false
        },
        detail: {
            show: false
        },
        itemStyle: {
            color: '#91CC75'
        }
    }];

    const generationRate = [{
            value: generationRateValue,
            name: 'Taxa de Produção',
            title: {
                fontSize: 13,
                offsetCenter: ['0%','-40%']
            },
            detail: {
                valueAnimation: true,
                offsetCenter: ['0%','-25%'],
            }
    }];

    const activePowerData = [{
            value: activePowerValue,
            name: 'Potência Instantânea',
            title: {
                fontSize: 13,
                offsetCenter: ['0%','-6%']
            },
            detail: {
                valueAnimation: true,
                offsetCenter: ['0%','8%'],
            }
    }];

    const status = [{
            value: synopticData.etsStatus,
            name: 'Status',
            title: {
                fontSize: 13,
                offsetCenter: ['0%','26%']
            },
            detail: {
                valueAnimation: true,
                offsetCenter: ['0%','40%'],
            }
    }];

    const generatedEnergySerie = {
        name: 'Energia Gerada',
        type: 'gauge',
        data: generatedEnergyData,
        startAngle: 90,
        endAngle: -270,
        tooltip:{
            valueFormatter: (value: any) => valueFormatter(value, 'kWh', expectedInjectedEnergy)
        },
        pointer: {
            show: false
        },
        progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: false,
            itemStyle: {
                borderWidth: 1,
                borderColor: '#000000'
            }
        },
        axisLine: {
            lineStyle: {
                width: 20
            }
        },
        splitLine: {
            show: false,
            distance: 0,
            length: 10
        },
        axisTick: {
            show: false
        },
        axisLabel: {
            show: false,
            distance: 50
        },
        radius: radius.toString() + '%',
        max: setMax(generatedEnergyValue, expectedInjectedEnergy)
    }

    const irradianceSerie = {
        name: 'Irradiação',
        type: 'gauge',
        data: irradianceData,
        startAngle: 90,
        endAngle: -270,
        tooltip:{
            valueFormatter: (value: any) => valueFormatter(value, 'kWh/m²', expectedIrradiance)
        },
        pointer: {
            show: false
        },
        progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: false,
            itemStyle: {
                borderWidth: 1,
                borderColor: '#000000'
            }
        },
        axisLine: {
            lineStyle: {
                width: 20
            }
        },
        splitLine: {
            show: false,
            distance: 0,
            length: 10
        },
        axisTick: {
            show: false
        },
        axisLabel: {
            show: false,
            distance: 50
        },
        radius: (radius - 11).toString() + '%',
        max: setMax(irradianceValue, expectedIrradiance),
        center: ['50%', '50%'],
    }

    // @ts-ignore
    const option: AChartXProps['option'] = {
        grid: {
            // left: 0,
            // top: 0,
            // right: 0,
            // bottom: 0,
            // width: '100px',
            // height: '100px',
            // x: 40,
            // y: 20,
            // x2: 40,
            // y2: 20
        },
        color: ['#5470C6', '#91CC75'],
        legend: {
            data: ['Energia Gerada', 'Irradiação'],
            right: 0,
            orient: 'vertical',
            align: 'right',
            textStyle: {
                opacity: 1
            },

        },
        tooltip:{
            trigger: 'item',
            borderWidth: 0,
            renderMode: 'richText',
        },
        series: [
            generatedEnergySerie,
            irradianceSerie,
            {
                name: 'Taxa de Produção',
                type: 'gauge',
                data: generationRate,
                progress: {
                    show: false,
                },
                pointer: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
                detail: {
                    width: '28%',
                    height: '8%',
                    fontSize: 12,
                    color: customColor(generationRate[0].value) ,
                    borderColor: customColor(generationRate[0].value),
                    borderRadius: 20,
                    borderWidth: 1,
                    formatter: '{value}%',
                    show: true
                },

            },
            {
                name: 'Potência instantânea',
                type: 'gauge',
                data: activePowerData,
                progress: {
                    show: false,
                },
                pointer: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
                detail: {
                    width: '48%',
                    height: '6%',
                    fontSize: 12,
                    color: 'rgba(0,0,0,0.65)',
                    borderColor: 'rgba(0,0,0,0.65)',
                    borderRadius: 20,
                    borderWidth: 1,
                    formatter: '{value}kW',
                    show: true
                },
            },
            {
                name: 'Status',
                data: status,
                type: 'gauge',
                progress: {
                    show: false,
                },
                pointer: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
                detail: {
                    width: '52%',
                    height: '7%',
                    fontSize: 12,
                    color: mapPowerStationStatusToColor(synopticData.etsStatus),
                    borderColor: mapPowerStationStatusToColor(synopticData.etsStatus),
                    borderRadius: 20,
                    borderWidth: 1,
                    formatter: synopticData.etsStatus ? synopticData.etsStatus : "Dado não encontrado",
                    show: true
                },
            }
        ],
    };

    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);

return (
    <ACard
        title={synopticData.powerStationName ? synopticData.powerStationName : ""}
        // headerActions={
        //     <>
        //         <FloatMenuItem
        //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
        //             text="Settings"
        //             disable={true}
        //             link={"/"}
        //         />
        //         <FloatMenuItem
        //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
        //             text="Expand"
        //             disable={true}
        //         />
        //         <FloatMenuItem
        //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
        //             text="Print"
        //             disable={true}
        //         />
        //     </>
        // }
        styleProps={{contentStyle: {paddingBottom: 0, marginBottom: 0, height: 350}}}
    >
        {
            isLoading ?
                <Skeleton animation="wave" height={300} width="100%" variant="rounded" />
                :
                <AChartX
                    key={`card-sinoptic-${openSubMenu}`}
                    option={option}
                    loading={false}
                    theme={theme.palette.mode}
                />

        }

    </ACard>
)
}

export default SynopticChart;