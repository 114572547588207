import {ADataGridColumn} from "@atiautomacao/ati-ui-library";

export const performanceAnalysisColumns = [
    {
        name: 'status',
        label: 'status',
        align: 'left',
        minWidth: 100,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: { name: 'status', condition: 'contains', value: '', sort: 'desc' }
    },
    {
        name: 'powerStationName',
        label: 'UFV',
        align: 'left',
        minWidth: 50,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'powerCapacityNominal',
        label: 'Cap. Instalada (kWp)',
        align: 'left',
        minWidth: 100,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'instantPower',
        label: 'potência (kW)',
        align: 'left',
        minWidth: 100,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'irradianceSum',
        label: 'irradiação (kWh/m²)',
        align: 'left',
        minWidth: 100,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'generatedEnergySum',
        label: 'energia (MWh)',
        align: 'left',
        minWidth: 100,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'performanceRatio',
        label: 'PR (%)',
        align: 'left',
        minWidth: 100,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'availability',
        label: 'disponibilidade (%)',
        align: 'left',
        minWidth: 100,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
] as ADataGridColumn[];
