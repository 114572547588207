import React, {FunctionComponent, useEffect, useState} from "react";
import {RootState} from "../../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../../Config/Hooks";
import {ComparisonSkid, getEntities} from "./SkidComparisonChartReducer";
import {format} from 'date-fns';
import {truncateNumber} from "../../../../Utils/NumberUtil";
import {
    AChartX,
    Period,
    Skeleton,
    XAxis,
    YAxis, PeriodSelectorGroupProps
} from "@atiautomacao/ati-ui-library"
import {checkDiffDays} from "../../../../Utils/DataUitils";
import DataNotFound from "../../../../Shared/Components/DataNotFoundMessage";
import useInterval from "../../../../Shared/Hooks/useInterval";
import {useTheme} from "@mui/material";


interface SeriesOption {
    name: string;
    type: string;
    unity: string;
    yAxisIndex?: number | undefined;
    data: any[];
    date?: any;
    tooltip?: any;
}

interface SkidComparisonChartProps {
    period?: Period;
    periodIntervalGroup? : PeriodSelectorGroupProps;
    powerPlant: any;
}


const SkidComparisonChart: FunctionComponent<SkidComparisonChartProps> = (props: SkidComparisonChartProps) => {
    const comparisonSkid: Array<ComparisonSkid> = useAppSelector((state: RootState) => state.skidComparisonChart.entities);
    const { loading } = useAppSelector((state: RootState) => state.skidComparisonChart);
    const dispatch = useAppDispatch();
    const [showGraph, setShowGraph] = useState(false);
    const theme = useTheme();
    // Setting range start period
    const initialPeriod: Period = {
        groupBy: "day",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };

    const max = (values: number[]) => {
        return Array.isArray(values) ? truncateNumber(Math.max(...values) * 1.15) : 0;
    }

    const valueFormatter = (value:any,fromDateTime:any, toDateTime:any) => {
        let data = formatCaption(fromDateTime, toDateTime)
        return  `${value} MWh ${data}`;
    }

    const [period, setPeriod] = useState<Period>(props.period ? props.period : initialPeriod);
    const [series, setSeries] = useState<Array<SeriesOption>>(new Array<SeriesOption>);
    const [xAxis, setXAxis] = useState<XAxis>();
    const [yAxis, setYAxis] = useState<Array<YAxis>>(new Array<YAxis>);

    function formatCaption(fromDateTime: Date, toDateTime: Date): string{

        let dateInterval;
        if (period.groupBy && (period.groupBy.valueOf() === "none" || period.groupBy.valueOf() === "week" || period.groupBy.valueOf() === "month")) {
            dateInterval = format(fromDateTime, 'dd/MM') + ' a ' + format(toDateTime, 'dd/MM')
        } else {
            dateInterval = format(fromDateTime, 'HH:mm' ) + ' a ' + format(toDateTime, 'HH:mm')
        }

        return dateInterval
    }

    const newNameGap = () => {
        if (period.groupBy) {
            switch (period.groupBy) {
                case "day":
                    return 40;
                case "week":
                    return 46;
                case "month":
                    return 50;
                default:
                    return 40;
            }
        }
        return 40;
    };


    function dateByPeriod(): string{

        let fromDateTime = new Date();
        let toDateTime = new Date();
        let captionData :  string ;

        if(period.fromDateTime) {
            fromDateTime = period.fromDateTime
        }
        if(period.toDateTime) {
            toDateTime = period.toDateTime
        }

        captionData = (formatCaption(fromDateTime, toDateTime))
        return captionData
    }

    function findSkidComparisonByPeriod() {
        let datePeriod: any = dateByPeriod()

        setShowGraph(comparisonSkid.length > 0)
        setXAxis({data: Array.isArray(comparisonSkid) ? comparisonSkid.map(item => item.skidName) : []});

        setSeries([
                {
                    name: 'Energia Gerada',
                    data: Array.isArray(comparisonSkid) ? comparisonSkid.map(item => truncateNumber(item.value, 2)) : [],
                    type: 'bar',
                    unity: 'MWh',
                    date: datePeriod,
                    tooltip:{
                        valueFormatter: (value: any) => valueFormatter(value, period.fromDateTime, period.toDateTime)
                    }
                },
                {
                    name: 'Energia Esperada',
                    data: Array.isArray(comparisonSkid) ? comparisonSkid.map(item => truncateNumber(item.valueExpectedEnergy, 2)) : [],
                    type: 'bar',
                    unity: 'MWh',
                    date: datePeriod,
                    tooltip:{
                        valueFormatter: (value: any) => valueFormatter(value, period.fromDateTime, period.toDateTime)
                    }
                }
            ]
        );

        const maxAxisY = max(comparisonSkid.map(item => item.value));
        const maxAxisYExpectedEnergy = max(comparisonSkid.map(item => item.valueExpectedEnergy));
        setYAxis([
            {
                name: 'MWh',
                max: maxAxisY && maxAxisYExpectedEnergy > 1 ? maxAxisY > maxAxisYExpectedEnergy ? maxAxisY : maxAxisYExpectedEnergy : 1,
                min: 0,
                interval: maxAxisY > 1 ? truncateNumber(maxAxisY / 5) : 0.20,
                nameGap: newNameGap(),
                nameLocation: "middle"
            }
        ])
    }

    useEffect(() => {
        if(props.period){
            setPeriod(props.period);
        }
    }, [props.period]);

    useEffect(() => {
        setSeries([])
    }, [props.powerPlant]);


    const fetchData = () => {
        if (checkDiffDays(period.toDateTime, period.fromDateTime) > 0) {
            dispatch(getEntities({ period, idPowerPlant: props.powerPlant }));
        }
    };

    useEffect(() => {
        fetchData();
    }, [period, dispatch, props.powerPlant]);

    useInterval(() => {
        fetchData();
    }, 60000); // 1 minute

    useEffect(() => {
        dateByPeriod();
        if (comparisonSkid) findSkidComparisonByPeriod();
    }, [comparisonSkid]);

    // @ts-ignore
    const option: AChartXProps['option'] = {
        color: ['#5470C6', '#EE6666', 'green', 'yellow'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            },
            renderMode: 'richText'
        },
        grid: {
            containLabel: true,
            top: 10,
            bottom: 10
        },
        label: {
            precision: 1,
            position: 'right',
            valueAnimation: true,
            fontFamily: 'monospace'
        },
        animationDuration: 2000,
        legend: {
            show: true,
            orient: 'vertical',
            right: 45,
            top: 10,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            textStyle: {
                opacity: 1
            },
            itemStyle: {
                opacity: 1
            },
            data: ['Energia Gerada', 'Energia Esperada']
        },
        xAxis: xAxis,
        yAxis: yAxis,
        series: series
    };

    return (
        <>
            {
                loading ?
                    <Skeleton animation="wave" height={289} variant="rounded" width={"100%"}/>
                    :
                    series && xAxis && yAxis && showGraph ?
                        <AChartX
                            option={option}
                            height={350}
                            width={"100%"}
                            loading={false}
                            theme={theme.palette.mode}
                        />
                        :
                        <DataNotFound boxStyle={{height: 289, width: '100%'}}/>
            }
        </>
    );
}
export default SkidComparisonChart;
