const config = {
  VERSION: process.env.REACT_APP_VERSION,
};

export default config;

export const GATEWAY_URL = process.env.REACT_APP_GATEWAY_URL;
export const GATEWAY_URL_NOTIFICATION = process.env.REACT_APP_GATEWAY_URL_NOTIFICATION;
export const AUTHENTICATION_URL = process.env.REACT_APP_AUTHENTICATION_URL;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const VERSION = process.env.REACT_APP_VERSION;
// export const SERVER_API_URL = 'http://10.20.30.50:8090/';

export const AUTHORITIES = {
  "SYSADMIN":"SYSADMIN",

  "VIEW_DASHBOARD":"VIEW_DASHBOARD",
  "VIEW_DASHBOARD_MAP":"VIEW_DASHBOARD_MAP",
  "VIEW_DASHBOARD_CHART":"VIEW_DASHBOARD_CHART",
  "VIEW_DASHBOARD_ALARM":"VIEW_DASHBOARD_ALARM",
  "DASHBOARD_ALARM_ACKNOWLEDGE":"DASHBOARD_ALARM_ACKNOWLEDGE",
  "DASHBOARD_ALARM_FINALIZE":"DASHBOARD_ALARM_FINALIZE",
  "VIEW_DASHBOARD_SYNOPTIC":"VIEW_DASHBOARD_SYNOPTIC",
  "VIEW_DASHBOARD_ACTIVE_POWER":"VIEW_DASHBOARD_ACTIVE_POWER",

  "VIEW_DASHBOARD_POWER_STATION":"VIEW_DASHBOARD_POWER_STATION",
  "VIEW_DASHBOARD_POWER_STATION_CHART":"VIEW_DASHBOARD_POWER_STATION_CHART",
  "VIEW_DASHBOARD_POWER_STATION_ALARM":"VIEW_DASHBOARD_POWER_STATION_ALARM",
  "DASHBOARD_POWER_STATION_ALARM_ACKNOWLEDGE":"DASHBOARD_POWER_STATION_ALARM_ACKNOWLEDGE",
  "DASHBOARD_POWER_STATION_ALARM_FINALIZE":"DASHBOARD_POWER_STATION_ALARM_FINALIZE",
  "VIEW_DASHBOARD_POWER_STATION_PLANT_LAYOUT":"VIEW_DASHBOARD_POWER_STATION_PLANT_LAYOUT",
  "VIEW_DASHBOARD_POWER_STATION_INVERTER":"VIEW_DASHBOARD_POWER_STATION_INVERTER",
  "VIEW_DASHBOARD_POWER_STATION_TRACKER":"VIEW_DASHBOARD_POWER_STATION_TRACKER",
  "VIEW_DASHBOARD_POWER_STATION_EQUIPMENT":"VIEW_DASHBOARD_POWER_STATION_EQUIPMENT",
  "VIEW_DASHBOARD_POWER_STATION_EQUIPMENT_DETAIL":"VIEW_DASHBOARD_POWER_STATION_EQUIPMENT_DETAIL",
  "SEND_POWER_STATION_EQUIPMENT_DETAIL_COMMAND":"SEND_POWER_STATION_EQUIPMENT_DETAIL_COMMAND",

  "VIEW_DASHBOARD_SKID":"VIEW_DASHBOARD_SKID",
  "VIEW_DASHBOARD_SKID_CHART":"VIEW_DASHBOARD_SKID_CHART",
  "VIEW_DASHBOARD_SKID_ALARM":"VIEW_DASHBOARD_SKID_ALARM",
  "VIEW_DASHBOARD_SKID_PLANT_LAYOUT":"VIEW_DASHBOARD_SKID_PLANT_LAYOUT",
  "VIEW_DASHBOARD_SKID_EQUIPMENT":"VIEW_DASHBOARD_SKID_EQUIPMENT",
  "VIEW_DASHBOARD_SKID_EQUIPMENT_DETAIL":"VIEW_DASHBOARD_SKID_EQUIPMENT_DETAIL",
  "SEND_SKID_EQUIPMENT_DETAIL_COMMAND":"SEND_SKID_EQUIPMENT_DETAIL_COMMAND",

  "VIEW_ANALYSIS":"VIEW_ANALYSIS",
  "SEARCH_ANALYSIS":"SEARCH_ANALYSIS",
  "SAVE_ANALYSIS":"SAVE_ANALYSIS",
  "DELETE_ANALYSIS":"DELETE_ANALYSIS",

  "SAVE_USER":"SAVE_USER",
  "SEARCH_USERS":"SEARCH_USERS",
  "VIEW_USER":"VIEW_USER",
  "DELETE_USER":"DELETE_USER",

  "SEARCH_SETTINGS": "SEARCH_SETTINGS",
  "SAVE_SETTINGS": "SAVE_SETTINGS",
  "DELETE_SETTINGS": "DELETE_SETTINGS",

  "SAVE_AUTHORITY":"SAVE_AUTHORITY",
  "SEARCH_AUTHORITIES":"SEARCH_AUTHORITIES",
  "VIEW_AUTHORITIES":"VIEW_AUTHORITIES",
  "DELETE_AUTHORITY":"DELETE_AUTHORITY",

  "SAVE_AUTHORITY_GROUP":"SAVE_AUTHORITY_GROUP",
  "SEARCH_AUTHORITIES_GROUP":"SEARCH_AUTHORITIES_GROUP",
  "VIEW_AUTHORITIES_GROUP":"VIEW_AUTHORITIES_GROUP",
  "DELETE_AUTHORITY_GROUP":"DELETE_AUTHORITY_GROUP",

  "SAVE_USER_AUTHORITY_GROUP":"SAVE_USER_AUTHORITY_GROUP",
  "SEARCH_USER_AUTHORITIES_GROUP":"SEARCH_USER_AUTHORITIES_GROUP",
  "VIEW_USER_AUTHORITIES_GROUP":"VIEW_USER_AUTHORITIES_GROUP",
  "DELETE_USER_AUTHORITY_GROUP":"DELETE_USER_AUTHORITY_GROUP",

  "VIEW_ACCOUNT":"VIEW_ACCOUNT",
  "SAVE_COUNTRY":"SAVE_COUNTRY",
  "SEARCH_COUNTRIES":"SEARCH_COUNTRIES",
  "VIEW_COUNTRY":"VIEW_COUNTRY",
  "DELETE_COUNTRY":"DELETE_COUNTRY",

  "SAVE_MACRO":"SAVE_MACRO",
  "SEARCH_MACRO":"SEARCH_MACRO",
  "VIEW_MACRO":"VIEW_MACRO",
  "DELETE_MACRO":"DELETE_MACRO",

  "SAVE_REGION":"SAVE_REGION",
  "SEARCH_REGION":"SEARCH_REGION",
  "VIEW_REGION":"VIEW_REGION",
  "DELETE_REGION":"DELETE_REGION",

  "SAVE_EQUIPMENT":"SAVE_EQUIPMENT",
  "SEARCH_EQUIPMENT":"SEARCH_EQUIPMENT",
  "VIEW_EQUIPMENT":"VIEW_EQUIPMENT",
  "DELETE_EQUIPMENT":"DELETE_EQUIPMENT",

  "SAVE_BROKER":"SAVE_BROKER",
  "SEARCH_BROKER":"SEARCH_BROKER",
  "VIEW_BROKER":"VIEW_BROKER",
  "DELETE_BROKER":"DELETE_BROKER",

  "SAVE_EQUIPMENT_TYPE":"SAVE_EQUIPMENT_TYPE",
  "SEARCH_EQUIPMENT_TYPE":"SEARCH_EQUIPMENT_TYPE",
  "VIEW_EQUIPMENT_TYPE":"VIEW_EQUIPMENT_TYPE",
  "DELETE_EQUIPMENT_TYPE":"DELETE_EQUIPMENT_TYPE",

  "SAVE_POWER_STATION_EXCEL":"SAVE_POWER_STATION_EXCEL",
  "SEARCH_POWER_STATION_EXCEL":"SEARCH_POWER_STATION_EXCEL",
  "VIEW_POWER_STATION_EXCEL":"VIEW_POWER_STATION_EXCEL",
  "DELETE_POWER_STATION_EXCEL":"DELETE_POWER_STATION_EXCEL",

  "SAVE_EQUIPMENT_CLASS":"SAVE_EQUIPMENT_CLASS",
  "SEARCH_EQUIPMENT_CLASS":"SEARCH_EQUIPMENT_CLASS",
  "VIEW_EQUIPMENT_CLASS":"VIEW_EQUIPMENT_CLASS",
  "DELETE_EQUIPMENT_CLASS":"DELETE_EQUIPMENT_CLASS",

  "SAVE_TELE_OBJECT_SPECIFICATION_TYPE":"SAVE_TELE_OBJECT_SPECIFICATION_TYPE",
  "SEARCH_TELE_OBJECT_SPECIFICATION_TYPE":"SEARCH_TELE_OBJECT_SPECIFICATION_TYPE",
  "VIEW_TELE_OBJECT_SPECIFICATION_TYPE":"VIEW_TELE_OBJECT_SPECIFICATION_TYPE",
  "DELETE_TELE_OBJECT_SPECIFICATION_TYPE":"DELETE_TELE_OBJECT_SPECIFICATION_TYPE",

  "SAVE_TELE_OBJECT":"SAVE_TELE_OBJECT",
  "SEARCH_TELE_OBJECT":"SEARCH_TELE_OBJECT",
  "VIEW_TELE_OBJECT":"VIEW_TELE_OBJECT",
  "DELETE_TELE_OBJECT":"DELETE_TELE_OBJECT",

  "SAVE_TELE_OBJECT_CONFIG":"SAVE_TELE_OBJECT_CONFIG",
  "SEARCH_TELE_OBJECT_CONFIG":"SEARCH_TELE_OBJECT_CONFIG",
  "VIEW_TELE_OBJECT_CONFIG":"VIEW_TELE_OBJECT_CONFIG",
  "DELETE_TELE_OBJECT_CONFIG":"DELETE_TELE_OBJECT_CONFIG",

  "SAVE_TELE_OBJECT_TYPE":"SAVE_TELE_OBJECT_TYPE",
  "SEARCH_TELE_OBJECT_TYPE":"SEARCH_TELE_OBJECT_TYPE",
  "VIEW_TELE_OBJECT_TYPE":"VIEW_TELE_OBJECT_TYPE",
  "DELETE_TELE_OBJECT_TYPE":"DELETE_TELE_OBJECT_TYPE",

  "SAVE_MOD_BUS_DATA_TYPES":"SAVE_MOD_BUS_DATA_TYPES",
  "SEARCH_MOD_BUS_DATA_TYPES":"SEARCH_MOD_BUS_DATA_TYPES",
  "VIEW_MOD_BUS_DATA_TYPES":"VIEW_MOD_BUS_DATA_TYPES",
  "DELETE_MOD_BUS_DATA_TYPES":"DELETE_MOD_BUS_DATA_TYPES",

  "SAVE_MOD_BUS_OBJECTS_TYPES":"SAVE_MOD_BUS_OBJECTS_TYPES",
  "SEARCH_MOD_BUS_OBJECTS_TYPES":"SEARCH_MOD_BUS_OBJECTS_TYPES",
  "VIEW_MOD_BUS_OBJECTS_TYPES":"VIEW_MOD_BUS_OBJECTS_TYPES",
  "DELETE_MOD_BUS_OBJECTS_TYPES":"DELETE_MOD_BUS_OBJECTS_TYPES",

  "SAVE_GRAFIC_ELEMENT":"SAVE_GRAFIC_ELEMENT",
  "SEARCH_GRAFIC_ELEMENT":"SEARCH_GRAFIC_ELEMENT",
  "VIEW_GRAFIC_ELEMENT":"VIEW_GRAFIC_ELEMENT",
  "DELETE_GRAFIC_ELEMENT":"DELETE_GRAFIC_ELEMENT",

  "SAVE_GRAPH_ELEMENT":"SAVE_GRAPH_ELEMENT",
  "SEARCH_GRAPH_ELEMENT":"SEARCH_GRAPH_ELEMENT",
  "VIEW_GRAPH_ELEMENT":"VIEW_GRAPH_ELEMENT",
  "DELETE_GRAPH_ELEMENT":"DELETE_GRAPH_ELEMENT",

  "SAVE_ALARM_SEVERITY":"SAVE_ALARM_SEVERITY",
  "SEARCH_ALARM_SEVERITY":"SEARCH_ALARM_SEVERITY",
  "VIEW_ALARM_SEVERITY":"VIEW_ALARM_SEVERITY",
  "DELETE_ALARM_SEVERITY":"DELETE_ALARM_SEVERITY",

  "SAVE_SKID":"SAVE_SKID",
  "SEARCH_SKID":"SEARCH_SKID",
  "VIEW_SKID":"VIEW_SKID",
  "DELETE_SKID":"DELETE_SKID",

  "SAVE_POWER_PLANT":"SAVE_POWER_PLANT",
  "SEARCH_POWER_PLANT":"SEARCH_POWER_PLANT",
  "VIEW_POWER_PLANT":"VIEW_POWER_PLANT",
  "DELETE_POWER_PLANT":"DELETE_POWER_PLANT",
  
  "SAVE_PROFILE":"SAVE_PROFILE",
  "SEARCH_PROFILE":"SEARCH_PROFILE",
  "VIEW_PROFILE":"VIEW_PROFILE",
  "DELETE_PROFILE":"DELETE_PROFILE",

  "SAVE_AUDIT":"SAVE_AUDIT",
  "SEARCH_AUDIT":"SEARCH_AUDIT",
  "VIEW_AUDIT":"VIEW_AUDIT",
  "DELETE_AUDIT":"DELETE_AUDIT",

  // REPORTS AND EXPORTS
  "VIEW_PDF_EXPORT":"VIEW_PDF_EXPORT",
  "VIEW_CSV_EXPORT":"VIEW_CSV_EXPORT",
  "VIEW_EXCEL_EXPORT":"VIEW_EXCEL_EXPORT",

  "SEARCH_REPORT":"SEARCH_REPORT",
  "SAVE_REPORT":"SAVE_REPORT",
  "DELETE_REPORT":"DELETE_REPORT",


  "PDF_EXPORT_REPORT_ALARM":"PDF_EXPORT_REPORT_ALARM",
  "EXPORT_REPORT_ALARM":"EXPORT_REPORT_ALARM",
  "CSV_EXPORT_REPORT_ALARM":"CSV_EXPORT_REPORT_ALARM",
  "EXCEL_EXPORT_REPORT_ALARM":"EXCEL_EXPORT_REPORT_ALARM",

  "PDF_EXPORT_REPORT_PERFORMANCE_SUMMARY":"PDF_EXPORT_REPORT_PERFORMANCE_SUMMARY",
  "EXPORT_REPORT_PERFORMANCE_SUMMARY":"EXPORT_REPORT_PERFORMANCE_SUMMARY",
  "CSV_EXPORT_REPORT_PERFORMANCE_SUMMARY":"CSV_EXPORT_REPORT_PERFORMANCE_SUMMARY",
  "EXCEL_EXPORT_REPORT_PERFORMANCE_SUMMARY":"EXCEL_EXPORT_REPORT_PERFORMANCE_SUMMARY",

  "PDF_EXPORT_REPORT_POWER_PLANT":"PDF_EXPORT_REPORT_POWER_PLANT",
  "EXPORT_REPORT_POWER_PLANT":"EXPORT_REPORT_POWER_PLANT",
  "CSV_EXPORT_REPORT_POWER_PLANT":"CSV_EXPORT_REPORT_POWER_PLANT",
  "EXCEL_EXPORT_REPORT_POWER_PLANT":"EXCEL_EXPORT_REPORT_POWER_PLANT",

  "PDF_EXPORT_REPORT_EQUIPMENT":"PDF_EXPORT_REPORT_EQUIPMENT",
  "EXPORT_REPORT_EQUIPMENT":"EXPORT_REPORT_EQUIPMENT",
  "CSV_EXPORT_REPORT_EQUIPMENT":"CSV_EXPORT_REPORT_EQUIPMENT",
  "EXCEL_EXPORT_REPORT_EQUIPMENT":"EXCEL_EXPORT_REPORT_EQUIPMENT",

  "PDF_EXPORT_REPORT_EQUIPMENT_CLASS":"PDF_EXPORT_REPORT_EQUIPMENT_CLASS",
  "EXPORT_REPORT_EQUIPMENT_CLASS":"EXPORT_REPORT_EQUIPMENT_CLASS",
  "CSV_EXPORT_REPORT_EQUIPMENT_CLASS":"CSV_EXPORT_REPORT_EQUIPMENT_CLASS",
  "EXCEL_EXPORT_REPORT_EQUIPMENT_CLASS":"EXCEL_EXPORT_REPORT_EQUIPMENT_CLASS",

  "PDF_EXPORT_REPORT_MANAGEMENT_REPORT":"PDF_EXPORT_REPORT_MANAGEMENT_REPORT",
  "EXPORT_REPORT_MANAGEMENT_REPORT":"EXPORT_REPORT_MANAGEMENT_REPORT",
  "CSV_EXPORT_REPORT_MANAGEMENT_REPORT":"CSV_EXPORT_REPORT_MANAGEMENT_REPORT",
  "EXCEL_EXPORT_REPORT_MANAGEMENT_REPORT":"EXCEL_EXPORT_REPORT_MANAGEMENT_REPORT",

  "PDF_EXPORT_REPORT_SKID":"PDF_EXPORT_REPORT_SKID",
  "EXPORT_REPORT_SKID":"EXPORT_REPORT_SKID",
  "CSV_EXPORT_REPORT_SKID":"CSV_EXPORT_REPORT_SKID",
  "EXCEL_EXPORT_REPORT_SKID":"EXCEL_EXPORT_REPORT_SKID",

  "PDF_EXPORT_REPORT_TELEOBJECTS":"PDF_EXPORT_REPORT_TELEOBJECTS",
  "EXPORT_REPORT_TELEOBJECTS":"EXPORT_REPORT_TELEOBJECTS",
  "CSV_EXPORT_REPORT_TELEOBJECTS":"CSV_EXPORT_REPORT_TELEOBJECTS",
  "EXCEL_EXPORT_REPORT_TELEOBJECTS":"EXCEL_EXPORT_REPORT_TELEOBJECTS",

  "PDF_EXPORT_REPORT_AUDIT":"PDF_EXPORT_REPORT_AUDIT",
  "EXPORT_REPORT_AUDIT":"EXPORT_REPORT_AUDIT",
  "CSV_EXPORT_REPORT_AUDIT":"CSV_EXPORT_REPORT_AUDIT",
  "EXCEL_EXPORT_REPORT_AUDIT":"EXCEL_EXPORT_REPORT_AUDIT",
// graph dashboard
  "VIEW_ENERGY_GENERATION_EXPORT":"VIEW_ENERGY_GENERATION_EXPORT",
  "PDF_ENERGY_GENERATION_EXPORT":"PDF_ENERGY_GENERATION_EXPORT",
  "CSV_ENERGY_GENERATION_EXPORT":"CSV_ENERGY_GENERATION_EXPORT",
  "EXCEL_ENERGY_GENERATION_EXPORT":"EXCEL_ENERGY_GENERATION_EXPORT",
  // YIELD
  "EXPORT_YIELD":"EXPORT_YIELD",
  "PDF_EXPORT_YIELD":"PDF_EXPORT_YIELD",
  "CSV_EXPORT_YIELD":"CSV_EXPORT_YIELD",
  "EXCEL_EXPORT_YIELD":"EXCEL_EXPORT_YIELD",
  // PR
  "EXPORT_PR":"EXPORT_PR",
  "PDF_EXPORT_PR":"PDF_EXPORT_PR",
  "CSV_EXPORT_PR":"CSV_EXPORT_PR",
  "EXCEL_EXPORT_PR":"EXCEL_EXPORT_PR",
  // PERFORMANCE ANALYSIS
  "EXPORT_PERFORMANCE_ANALYSIS":"EXPORT_PERFORMANCE_ANALYSIS",
  "PDF_EXPORT_PERFORMANCE_ANALYSIS":"PDF_EXPORT_PERFORMANCE_ANALYSIS",
  "CSV_EXPORT_PERFORMANCE_ANALYSIS":"CSV_EXPORT_PERFORMANCE_ANALYSIS",
  "EXCEL_EXPORT_PERFORMANCE_ANALYSIS":"EXCEL_EXPORT_PERFORMANCE_ANALYSIS",
  // POWER PU
  "EXPORT_POWER_PU":"EXPORT_POWER_PU",
  "PDF_EXPORT_POWER_PU":"PDF_EXPORT_POWER_PU",
  "CSV_EXPORT_POWER_PU":"CSV_EXPORT_POWER_PU",
  "EXCEL_EXPORT_POWER_PU":"EXCEL_EXPORT_POWER_PU",
  // POWER VC IRRADIANCE
  "EXPORT_POWER_VC_IRRADIANCE":"EXPORT_POWER_VC_IRRADIANCE",
  "PDF_EXPORT_POWER_VC_IRRADIANCE":"PDF_EXPORT_POWER_VC_IRRADIANCE",
  "CSV_EXPORT_POWER_VC_IRRADIANCE":"CSV_EXPORT_POWER_VC_IRRADIANCE",
  "EXCEL_EXPORT_POWER_VC_IRRADIANCE":"EXCEL_EXPORT_POWER_VC_IRRADIANCE",
  // SKID COMPARISON
  "EXPORT_SKID_COMPARISON":"EXPORT_SKID_COMPARISON",
  "PDF_EXPORT_SKID_COMPARISON":"PDF_EXPORT_SKID_COMPARISON",
  "CSV_EXPORT_SKID_COMPARISON":"CSV_EXPORT_SKID_COMPARISON",
  "EXCEL_EXPORT_SKID_COMPARISON":"EXCEL_EXPORT_SKID_COMPARISON",
  // CAPACITY FACTOR
  "EXPORT_CAPACITY_FACTOR":"EXPORT_CAPACITY_FACTOR",
  "PDF_EXPORT_CAPACITY_FACTOR":"PDF_EXPORT_CAPACITY_FACTOR",
  "CSV_EXPORT_CAPACITY_FACTOR":"CSV_EXPORT_CAPACITY_FACTOR",
  "EXCEL_EXPORT_CAPACITY_FACTOR":"EXCEL_EXPORT_CAPACITY_FACTOR",
  // TRANSFORMER TEMPERATURE
  "EXPORT_TRANSFORMER_TEMPERATURE":"EXPORT_TRANSFORMER_TEMPERATURE",
  "PDF_EXPORT_TRANSFORMER_TEMPERATURE":"PDF_EXPORT_TRANSFORMER_TEMPERATURE",
  "CSV_EXPORT_TRANSFORMER_TEMPERATURE":"CSV_EXPORT_TRANSFORMER_TEMPERATURE",
  "EXCEL_EXPORT_TRANSFORMER_TEMPERATURE":"EXCEL_EXPORT_TRANSFORMER_TEMPERATURE",
  //POWER INVERTERS
  "EXPORT_POWER_INVERTERS":"EXPORT_POWER_INVERTERS",
  "PDF_EXPORT_POWER_INVERTERS":"PDF_EXPORT_POWER_INVERTERS",
  "CSV_EXPORT_POWER_INVERTERS":"CSV_EXPORT_POWER_INVERTERS",
  "EXCEL_EXPORT_POWER_INVERTERS":"EXCEL_EXPORT_POWER_INVERTERS",
  // GENERATION RATE
  "EXPORT_GENERATION_RATE":"EXPORT_GENERATION_RATE",
  "PDF_EXPORT_GENERATION_RATE":"PDF_EXPORT_GENERATION_RATE",
  "CSV_EXPORT_GENERATION_RATE":"CSV_EXPORT_GENERATION_RATE",
  "EXCEL_EXPORT_GENERATION_RATE":"EXCEL_EXPORT_GENERATION_RATE",

  //RECEIVE - SEND COMMAND
  "VIEW_COMMAND_HISTORY":"VIEW_COMMAND_HISTORY",

};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};
export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
