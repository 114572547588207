import React, {FunctionComponent, useEffect, useState} from "react";
import {RootState} from "../../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../../Config/Hooks";
import {CapacityFactor, getEntities} from "./CapacityFactorVSPowerReducer";
import {format} from 'date-fns';
import {ptBR} from 'date-fns/locale';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faFileCsv} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import {downloadFile} from "../../../../Utils/DownloadUtils";
import {truncateNumber} from "../../../../Utils/NumberUtil";
import {AUTHORITIES, GATEWAY_URL} from "../../../../Config/Constants";
import {
    ACard,
    AChartX,
    APopoverIcon,
    Period,
    PeriodSelector,
    Skeleton,
    XAxis,
    YAxis
} from "@atiautomacao/ati-ui-library";
import {checkDiffDays} from "../../../../Utils/DataUitils";
import DataNotFound from "../../../../Shared/Components/DataNotFoundMessage";
import useInterval from "../../../../Shared/Hooks/useInterval";
import {hasPermission} from "../../../../Shared/Auth/AuthenticationUtil";
import {useTheme} from "@mui/material";

export interface SeriesOption {
    name: string;
    type: string;
    unity: string;
    yAxisIndex?: number | undefined;
    smooth?: boolean;
    data: any[];
    date?: any;
    tooltip?: any;
}


const CapacityFactorChart: FunctionComponent<{ powerPlant: any; }> = ({powerPlant}) => {
    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToSearchByCsv = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_CSV_EXPORT, AUTHORITIES.EXPORT_CAPACITY_FACTOR, AUTHORITIES.CSV_EXPORT_CAPACITY_FACTOR
    ]);

    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const { loading } = useAppSelector((state: RootState) => state.capacityFactorChart);
    const capacityFactorEntity: Array<CapacityFactor> = useAppSelector((state: RootState) => state.capacityFactorChart.entities);
    const dispatch = useAppDispatch();
    const [showGraph, setShowGraph] = useState(false);

    const theme = useTheme();
    // Setting range start period
    const initialPeriod: Period = {
        groupBy: "week",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };
    const [period, setPeriod] = useState<Period>(initialPeriod);

    // returns the greatest max of the axes
    const max = (dataYAxis1: number[], dataYAxis2: number[]) => {
        const yAxis1 = Array.isArray(dataYAxis1) ? Math.max(...dataYAxis1) : 0
        const yAxis2 = Array.isArray(dataYAxis2) ? Math.max(...dataYAxis2) : 0
        return yAxis1 > yAxis2 ? truncateNumber(yAxis1) : truncateNumber(yAxis2);
    }

    // return the group by
    const handleOnGroupByTypeOfPeriod = (valueTypePeriod: string) => {
        if (valueTypePeriod.includes('week') || valueTypePeriod.includes('month')) {
            return 'day';
        } else if (valueTypePeriod.includes('year')) {
            return 'month';
        }
        return 'year';
    }

    const [series, setSeries] = useState<Array<SeriesOption>>(new Array<SeriesOption>);
    const [xAxis, setXAxis] = useState<XAxis>();
    const [yAxis, setYAxis] = useState<Array<YAxis>>(new Array<YAxis>);


    const newNameGap = () => {
        if (period.groupBy) {
            switch (period.groupBy) {
                case "week":
                    return 40;
                case "month":
                    return 46;
                case "year":
                    return 50;
                case "general":
                    return 70;
                default:
                    return 40;
            }
        }
        return 40;
    };

    function findCapacityEnergyDataByPeriod() {

        let datePeriod: any[];
        if (period.groupBy.valueOf() === "week" || period.groupBy.valueOf() === "month") {
            datePeriod = Array.isArray(capacityFactorEntity) ? capacityFactorEntity
                .map(item => format(new Date(item.dateTime + "T00:00:00"), "dd/MM")) : [];
        } else if (period.groupBy.valueOf() === "year") {
            datePeriod = Array.isArray(capacityFactorEntity) ? capacityFactorEntity
                .map(item => format(new Date(item.dateTime + "T00:00:00"), "MMM", {locale: ptBR})) : []
        } else {
            datePeriod = Array.isArray(capacityFactorEntity) ? capacityFactorEntity.map(item => format(new Date(item.dateTime + "T00:00:00"), "yyyy")) : [];
        }

        setShowGraph(capacityFactorEntity.length > 0);
        setXAxis({ data: datePeriod, nameGap: 30});
        setSeries([
                {
                    name: 'Energia Gerada',
                    data: Array.isArray(capacityFactorEntity) ? capacityFactorEntity.map(item =>item.generatedEnergy ? truncateNumber(item.generatedEnergy, 2) : "-") : [],
                    type: 'bar',
                    unity: 'MWh',
                    tooltip: {
                        valueFormatter: (value: number) => value + ' ' + 'MWh'
                    }
                },
                {
                    name: 'Energia Injetada',
                    data: Array.isArray(capacityFactorEntity) ? capacityFactorEntity.map(item => item.injectedEnergy ? truncateNumber(item.injectedEnergy, 2): "-") : [],
                    type: 'bar',
                    unity: 'MWh',
                    tooltip: {
                        valueFormatter: (value: number) => value + ' ' + 'MWh'
                    }
                },
                {
                    name: 'Fator de Capacidade Real',
                    data: Array.isArray(capacityFactorEntity) ? capacityFactorEntity.map(item => item.capacityFactor ? truncateNumber(item.capacityFactor, 2) : "-") : [],
                    type: 'line',
                    yAxisIndex: 1,
                    unity: '%',
                    smooth: true,
                    tooltip: {
                        valueFormatter: (value: number) => value + ' ' + '%'
                    }
                },
                {
                    name: 'Fator de Capacidade Esperado',
                    data: Array.isArray(capacityFactorEntity) ? capacityFactorEntity.map(item => item.expectedCapacityFactor ? truncateNumber(item.expectedCapacityFactor, 2) : "-"): [],
                    type: 'line',
                    yAxisIndex: 1,
                    unity: '%',
                    smooth: true,
                    tooltip: {
                        valueFormatter: (value: number) => value + ' ' + '%'
                    }
                }
            ]
        );
        setYAxis([
            {
                name: 'MWh',
                max: max(capacityFactorEntity.map(item => item.generatedEnergy), capacityFactorEntity.map(item => item.injectedEnergy)),
                min: 0,
                interval: max(capacityFactorEntity.map(item => item.generatedEnergy), capacityFactorEntity.map(item => item.injectedEnergy)) / 2,
                nameGap: newNameGap(),
                nameLocation: "middle"
            },
            {
                name: '%',
                max: max(capacityFactorEntity.map(item => item.capacityFactor), capacityFactorEntity.map(item => item.expectedCapacityFactor)),
                min: 0,
                interval: truncateNumber(max(capacityFactorEntity.map(item => item.capacityFactor), capacityFactorEntity.map(item => item.expectedCapacityFactor))/4),
                nameGap: 55,
                nameLocation: "middle"
            }
        ])
    }

    useEffect(() => {
        if(checkDiffDays(period.toDateTime, period.fromDateTime) > 0) {
            dispatch(getEntities({period, powerPlant}));
        }
    }, [period, powerPlant]);

    useInterval(() => {
        if(checkDiffDays(period.toDateTime, period.fromDateTime) > 0) {
            dispatch(getEntities({period, powerPlant}));
        }
    }, 60000) // 5 minutes

    useEffect(() => {
        if (capacityFactorEntity) findCapacityEnergyDataByPeriod();
    }, [capacityFactorEntity]);

    const handleDownloadCSV = () => {
        const valueTypePeriod = handleOnGroupByTypeOfPeriod(period.groupBy);

        const params = {
            startDateTime: format(period.fromDateTime ? period.fromDateTime : new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss'),
            endDateTime: format(period.toDateTime ? period.toDateTime : new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss')
        }
        const apiUrl = GATEWAY_URL+`/api/capacity-factor-energy/csv/${valueTypePeriod}?`
        downloadFile(apiUrl, params, 'fator-de-capacidade-energia.csv');
    };

    // @ts-ignore
    const option: AChartXProps['option'] = {
        color: ['#5470C6', '#EE6666', '#91CC75', '#FAC858'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            },
            renderMode: 'richText'
        },
        xAxis: xAxis,
        yAxis: yAxis,
        legend: {
            show: true,
            orient: 'vertical',
            right: 45,
            top: 10,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            textStyle: {
                opacity: 1
            },
            itemStyle: {
                opacity: 1
            },
            data: ['Energia Gerada', 'Energia Injetada', 'Fator de Capacidade Real', 'Fator de Capacidade Esperado']
        },
        grid: {
            containLabel: true,
            top: 10,
            bottom: 10
        },
        label: {
            precision: 1,
            position: 'right',
            valueAnimation: true,
            fontFamily: 'monospace'
        },
        animationDuration: 2000,
        series: series
    };

    return (
        <>
            <ACard
                key={`chart-power-capacity-${openSubMenu}`}
                styleProps={{
                    cardStyle: {height: 445},
                    contentStyle: {padding: 0}
                }}
                headerControlsPosition={"header"}
                title="Fator de Capacidade e Energia"
                headerControls={<>
                    <PeriodSelector
                        styleProps={{base: {flexDirection: "row", display: "flex", alignItems: "center"}}}
                        mode="hybrid"
                        hideDatetime={false}
                        disableHourSelector={true}
                        inputFormat={'dd/MM/yyyy'}
                        hideGroup={false}
                        periodInterval={{
                            week: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 6},
                            month: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 30},
                            year: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 12},
                            general: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 7}
                        }}
                        disabled={loading}
                        period={period}
                        onChange={setPeriod}
                        groupByOptions={["week", "month", "year", "general"]}
                    />
                </>}
                // headerActions={
                //     <>
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
                //             text="Settings"
                //             disable={true}
                //             link={"/"}
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
                //             text="Expand"
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
                //             text="Print"
                //         />
                //     </>
                // }
                footerActions={
                    <APopoverIcon icon={<FontAwesomeIcon icon={faDownload} fontSize={20}/>}>
                        <IconButton onClick={handleDownloadCSV} disabled={!isAuthorizedToSearchByCsv}>
                            <FontAwesomeIcon icon={faFileCsv} fontSize={20} />
                        </IconButton>
                    </APopoverIcon>
                }
            >
                {
                    loading ?
                        <Skeleton animation="wave" height={289} variant="rounded" width={"100%"} />
                        :
                        series && xAxis && yAxis && showGraph ?
                            <AChartX
                                option={option}
                                height={289}
                                loading={false}
                                theme={theme.palette.mode}
                            />
                            :
                            <DataNotFound boxStyle={{height: 289, width: '100%'}}/>
                }
            </ACard>
        </>
    );
}
export default CapacityFactorChart;
