import React, {FunctionComponent} from "react";
import {
    ACard,
    AChartX
} from "@atiautomacao/ati-ui-library";
import {Grid, useTheme} from "@mui/material";
import {TeleObjectData} from "./EquipmentDetailsSkid";
import {format} from "date-fns";
import {truncateNumber} from "../../../../Utils/NumberUtil";

const TeleObjectChart: FunctionComponent<{teleObjectData?: TeleObjectData}> = ({teleObjectData}) =>{
    const callback = (params:any) => {
        let formatDate = isYearOrGeneral() ? 'dd/MM/yyyy HH:mm' : 'dd/MM HH:mm'
        let dateStr = format(new Date(params[0].axisValue), formatDate);

        let result = dateStr + '<br/>';
        let unit = teleObjectData?.unit != null ? teleObjectData?.unit : ""
        params.forEach(function (item: any) {
            result += item.marker + " " + item.seriesName + "&nbsp;&nbsp;&nbsp;&nbsp;" + "<strong>" + item.value[1] + " " + unit + "</strong>" + "<br/>";
        });

        return result;
    }
    const setFormatter = () => {
        if(teleObjectData?.periodType){
            if(teleObjectData.periodType.valueOf() === "day"){
                return '{HH}:{mm}'
            }
            if(teleObjectData.periodType.valueOf() === "week"){
                return '{dd}/{MM}'
            }
            if(teleObjectData.periodType.valueOf() === "month"){
                return '{dd}/{MM}'
            }
            if(teleObjectData.periodType.valueOf() === "year"){
                return '{MMM}'
            }
            if(teleObjectData.periodType.valueOf() === "general"){
                return '{yyyy}'
            }
        }
    }
    const theme = useTheme();
    const setInterval = () => {
        if(teleObjectData?.periodType){
            if(teleObjectData.periodType.valueOf() === "day"){
                return 3600 * 1000 // 1 hour
            }
            if(teleObjectData.periodType.valueOf() === "week"){
                return 3600 * 1000 * 24 // 1 day
            }
            if(teleObjectData.periodType.valueOf() === "month"){
                return 3600 * 1000 * 24 * 3 // 3 days
            }
            if(teleObjectData.periodType.valueOf() === "year"){
                return 3600 * 1000 * 24 * 30 //30 days
            }
            if(teleObjectData.periodType.valueOf() === "general"){
                return 3600 * 1000 * 24 * 360 // 1 year
            }
        }
    }

    const isYearOrGeneral = () => {
        if(teleObjectData?.periodType){
            if(teleObjectData.periodType.valueOf() === "year" || teleObjectData.periodType.valueOf() === "general"){
                return true
            }
        }
        return false
    }
    // @ts-ignore
    const option: AChartXProps['option'] = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985',
                    formatter: function (params: any) {
                        const formatDate = isYearOrGeneral() ? "dd/MM/yyyy" : "dd/MM"

                        if (params.axisDimension === 'x') {
                            return format(new Date(params.value), formatDate);
                        }
                        if (params.axisDimension === 'y') {
                            return truncateNumber(params.value,2);
                        }
                    }
                }
            },
            formatter: callback
        },
        legend: {
            show: true,
            orient: 'vertical',
            right: '16%',
            top: '10%',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            textStyle: {
                opacity: 1
            },
            itemStyle: {
                opacity: 1
            },
            data: [teleObjectData?.name]
        },
        toolbox: {
            right: '16%',
            top: -7,
            itemSize: 15,
            feature: {
                dataView: { show: false, readOnly: false },
                dataZoom: {
                    yAxisIndex: 'none'
                },
                restore: { show: true},
            }
        },
        dataZoom: [
            {
                type: 'inside',
                throttle: 50
            },
        ],
        grid: {
            containLabel: true,
            top: 10,
            bottom: 10
        },
        label: {
            precision: 1,
            position: 'right',
            valueAnimation: true,
            fontFamily: 'monospace'
        },
        animationDuration: 1000,
        xAxis: {
            type: 'time',
            axisLabel: {
                formatter: setFormatter(),
            },
            minInterval: setInterval(),
            maxInterval: setInterval()
        },
        yAxis: {
            name: teleObjectData?.unit,
            type: 'value',
            nameLocation: 'middle',
            nameGap: 50,
            nameRotate: 0,
        },
        series: {
            name: teleObjectData?.name,
            type: 'line',
            data: teleObjectData?.data.map((item,index) => {
                return [teleObjectData?.time[index], item != null ? truncateNumber(item, 2) : '-']
            }),
            showSymbol: false,
        }
    };
    return (
        <Grid item xs={12} sm={12} md={12}>
            <ACard
                title={teleObjectData?.name + " - " + teleObjectData?.teleObjectType}
            >
                {
                    <AChartX
                        height={300}
                        option={option}
                        theme={theme.palette.mode}
                    />
                }
            </ACard>
        </Grid>
    );
}
export default TeleObjectChart;